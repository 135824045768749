import React from 'react'
import Helmet from 'react-helmet'

import Layout from "../../components/layout"
import BgImage from '../../components/bg-image'

const Tiling = () => (
  <Layout>
    <Helmet title="Pool" />
    <BgImage image="/assets/pool-interiors.jpg" className="top">
      <h1>Tiling</h1>
    </BgImage>
    <section className="content">
      <h2>Waterline Tiles – Ceramic Mosaics</h2>
      <p>
        Waterline tiles are extremely durable as well as offering almost
        limitless design and colour combination so your pool can stand out. The
        tiles are installed directly under the coping and help with the
        appearance of your pool. The main reason ceramic mosaics are great for
        your renovated pool is they are very easy to clean and avoid a scum line
        building up in the pool. We recommend a 200 to 300mm deep waterline tile
        band to give the appearance the tiles are well under the water line.
        Mosaic tiles are any tiles 100mm or less in size and are typically 25mm
        or 50mm in size.
      </p>
      <h2>Waterline Tiles – Glass Mosaics</h2>
      <p>
        We use Ezarri Spanish glass mosaic tiles as they are known world-wide
        for their high-quality, durability and magnificent range of hues and
        colours. The mosiacs are layed directly under the coping and help with
        the appearance of your pool. Glass mosiacs are very easy to clean and
        avoid a scum line building up in the pool. We recommend a 200 to 300mm
        deep waterline tile band to give the appearance the tiles are well under
        the water line. The tiles come in 25mm, 36mm or 50mm size. All tiles use
        the unique dot mount system allowing 93%-95% glue coverage on the back
        of the tile.
      </p>
      <h2>Fully Tiled Pools</h2>
      <p>
        Fully tiling a pool is the premium choice for your swimming pool. It
        transforms your swimming pool into an artisan masterpiece. The tiling of
        water features and pools of water has been practiced for many thousands
        of years in all different environments. Its wide use is due to its long
        life and natural beauty. Unlike other surfaces it is a highly resistant
        to attacks from imbalanced water and any stains are easily removed the
        face of the tile. With a wide range of tile manufacturers specialising
        in swimming pools there the possibilities and colour combinations are
        endless. Contact us today to help you choose the right colour to match
        with your existing surrounds.
      </p>
    </section>
  </Layout>
)

export default Tiling
