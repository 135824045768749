import React from 'react'

export default class BgImage extends React.Component {
  render () {
    const { element, image, ...tagProps } = this.props
    const Element = element
    const style = {
      backgroundImage: `url(${image})`
    }
    return (
      <Element style={style} {...tagProps}>
        {this.props.children}
      </Element>
    )
  }
}

BgImage.defaultProps = {
  element: 'div'
}
